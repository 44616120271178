import './layout.scss';
import Vue from 'vue';
import layoutBase from '../layout-base';

/* eslint-disable no-new*/
new Vue({
	extends: layoutBase,
	el: '[data-layout-id=PPNYI-app]'
});
/* eslint-enable*/
